export const debounce = (func, wait, immediate) => {
    var timeout
    return function() {
        var context = this,
            args = arguments
        var later = function() {
            timeout = null
            if (!immediate) func.apply(context, args)
        }
        var callNow = immediate && !timeout
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
        if (callNow) func.apply(context, args)
    }
}

export const throttle = (func, limit) => {
    let inThrottle
    return function() {
        const args = arguments
        const context = this
        if (!inThrottle) {
            func.apply(context, args)
            inThrottle = true
            setTimeout(() => (inThrottle = false), limit)
        }
    }
}

export const scrollTop = (callback) => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop

    if (scrollTop) {
        window.scrollBy({ top: -90000, left: 0, behavior: "smooth" })

        if (callback) {
            window.addEventListener("scroll", callback, { once: true })
        }
    } else if (callback) {
        callback()
    }
}
