import Flickity from "flickity"
import "flickity/css/flickity.css"

const slider = {}

slider.init = () => {
    const $slider = document.querySelector(".-js-slider")

    if (!$slider || $slider.querySelectorAll(".-js-slider-item").length < 2) {
        return
    }

    $slider.parentElement.classList.add("-js-upgraded")

    const options = {
        autoPlay: 7000,
        pauseAutoPlayOnHover: false,
        prevNextButtons: false,
        wrapAround: true,
        selectedAttraction: 0.03,
        friction: 0.4,
    }

    new Flickity($slider, options)
}

export default slider
