import { scrollTop } from "@/utils/utils"

const header = {}

header.init = () => {
    const $body = document.querySelector("body")
    const $toggle = document.querySelector(".-js-menu-toggle")

    if (!$toggle) {
        return
    }

    $toggle.addEventListener("click", (e) => {
        e.preventDefault()

        if ($body.classList.contains("-no-scroll")) {
            $body.classList.remove("-no-scroll", "-show-menu")
        } else {
            scrollTop(() => {
                $body.classList.add("-no-scroll", "-show-menu")
            })
        }
    })
}

export default header
