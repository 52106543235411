// Styles
import "./index.styl"
// External assets
import { Button } from "@framework"
// Components
import Slider from "./component/slider/slider"
import Gallery from "./component/gallery/gallery"
import Modal from "./component/modal/modal"
// Templates
import Main from "./template/main/main.js"
import Header from "./template/header/header.js"
import Contact from "./template/contact/contact"

// Init before DOMContentLoaded
Main.init()
Contact.init()

document.addEventListener("DOMContentLoaded", () => {
    // Components
    Button.init()
    Slider.init()
    Gallery.init()
    Modal.init()
    // Templates
    Header.init()
})
