import "lightgallery.js"
import "lg-autoplay.js"
import "picturefill"

export default {
    init() {
        const $galleries = document.querySelectorAll(".-js-gallery")
        if (!$galleries.length) {
            return
        }

        for (const $gallery of $galleries) {
            lightGallery($gallery, {
                selector: "img",
                download: false,
            })
        }
    },
}
